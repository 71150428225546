
import { Options, Vue } from 'vue-class-component';
import RegisterModal from '@/common/components/register-modal.component.vue';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'PolicyAlert',
	components: { RegisterModal },
})
export default class PolicyAlert extends Vue {
	@Prop() requestData!: {
		name: string;
		policy: string;
		onAgree: (val: boolean) => void;
		options: { label: string; value: boolean }[];
	};
	created(): void {
		this.requestData.options.reverse();
	}
	onAgree(val: boolean) {
		this.$emit('closeRequest');
		this.requestData.onAgree(val);
	}
}
